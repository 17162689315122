<template>
    <div>
        <b-row>
            <b-col md="12">
                <b-card text-variant="center" class="card card-congratulations">
                    <b-img
                        :src="
                            require('@/assets/images/elements/decore-left.png')
                        "
                        class="congratulations-img-left"
                    /><b-img
                        :src="
                            require('@/assets/images/elements/decore-right.png')
                        "
                        class="congratulations-img-right"
                    />
                    <b-avatar variant="primary" size="70" class="shadow mb-2">
                        <feather-icon size="28" icon="AwardIcon" />
                    </b-avatar>
                    <h1 class="mb-1 mt-50 text-white">
                        Welcome to LEECHALL.IO
                    </h1>
                    <b-card-text class="m-auto w-75">
                        LEECHALL.IO is paid service allows you to download files
                        instantly and at the best your Internet speed.
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="4">
                <b-card>
                    <b-avatar variant="primary" size="50" class="shadow mb-2">
                        <feather-icon size="28" icon="CpuIcon" />
                    </b-avatar>
                    <h2 class="text-primary mb-1">High Performance</h2>
                    <b-card-text
                        >We operate with the best network provider, process your
                        files with the high speed. Help you download files
                        quickly.</b-card-text
                    >
                </b-card>
            </b-col>
            <b-col md="4">
                <b-card>
                    <b-avatar variant="primary" size="50" class="shadow mb-2">
                        <feather-icon size="28" icon="DownloadCloudIcon" />
                    </b-avatar>
                    <h2 class="text-primary mb-1">Instant Downloads</h2>
                    <b-card-text
                        >Get your files instantly through our easy-to-use
                        interface. You don't need click any ads to get the links
                        anymore.
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col md="4">
                <b-card>
                    <b-avatar variant="primary" size="50" class="shadow mb-2">
                        <feather-icon size="28" icon="CloudIcon" />
                    </b-avatar>
                    <h2 class="text-primary mb-1">Many Hosters</h2>
                    <b-card-text
                        >A wide range of unrestricted hosts is offered so that
                        you can download your files without any restrictions.
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col md="4">
                <b-card>
                    <b-avatar variant="primary" size="50" class="shadow mb-2">
                        <feather-icon size="28" icon="DollarSignIcon" />
                    </b-avatar>
                    <h2 class="text-primary mb-1">Prices</h2>
                    <b-card-text
                        >A lower price than the majority of file hosters while
                        still offering more features and hosters.</b-card-text
                    >
                </b-card>
            </b-col>
            <b-col md="4">
                <b-card>
                    <b-avatar variant="primary" size="50" class="shadow mb-2">
                        <feather-icon size="28" icon="PocketIcon" />
                    </b-avatar>
                    <h2 class="text-primary mb-1">Trustly</h2>
                    <b-card-text
                        >Leechall.com has been working since 2016. Leechall.io
                        is paid service with high performance for anyone hate
                        ads.</b-card-text
                    >
                </b-card>
            </b-col>
            <b-col md="4">
                <b-card>
                    <b-avatar variant="primary" size="50" class="shadow mb-2">
                        <feather-icon size="28" icon="KeyIcon" />
                    </b-avatar>
                    <h2 class="text-primary mb-1">Payment Methods</h2>
                    <b-card-text
                        >Now accepting Cryptocurrency, WebMoney, Perfect Money
                        ... More payment methods will be updated
                        soon.</b-card-text
                    >
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card text-variant="center" border-variant="warning">
                    <b-avatar variant="warning" size="50" class="shadow mb-2">
                        <feather-icon size="28" icon="FlagIcon" />
                    </b-avatar>
                    <h2 class="text-warning mb-1">Legal</h2>
                    <b-card-text
                        >Our service acts like a gateway between you and the
                        filehost service, redirecting the file directly to you
                        from filehosts servers. We never save the file on our
                        servers, therefore <strong>Leechall.io</strong> is
                        totally within the boundaries of legal
                        work.</b-card-text
                    >
                </b-card>
            </b-col>
        </b-row>
        <b-row v-if="!this.$store.getters.isLoggedIn">
            <b-col md="12">
                <b-card text-variant="center" border-variant="primary">
                    <b-avatar variant="primary" size="70" class="shadow mb-2">
                        <feather-icon size="28" icon="LinkIcon" />
                    </b-avatar>
                    <h2 class="text-primary">
                        Create an account now! It's Free!
                    </h2>
                    <b-card-text class="font-small-4 mb-2">
                        Our service will not let you down.
                    </b-card-text>

                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        :to="{ name: 'auth-register' }"
                    >
                        Create Account
                    </b-button>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BAvatar,
    BImg,
    BCol,
    BRow,
    BCard,
    BCardText,
    BLink,
    BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BCol,
        BRow,
        BCard,
        BCardText,
        BLink,
        BImg,
        BButton,
        BAvatar,
    },
    directives: {
        Ripple,
    },
};
</script>

<style></style>
